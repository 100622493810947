import React, { useEffect } from 'react'
import router from '../router'
import { useRoutes } from 'react-router-dom'
import initReactFastclick from 'react-fastclick'
import 'default-passive-events' // 解决 https://blog.csdn.net/Wildness_/article/details/123190078
import VConsole from 'vconsole'
import { saveDomainToStorage } from '@/utils/host'
import useGuideToLogin from '@/hooks/useGuideToLogin'
import * as utilsStorage from '@/utils/storage'

function App() {
  const element = useRoutes(router)
  // console.log('>>>>>',process.env.NETWORK)
  if(!process.env.NETWORK) {
    useGuideToLogin();
  } else {
    utilsStorage.setItem('systemProcess', 'local');
  }
 
  useEffect(() => {
    saveDomainToStorage() // 打开应用保存域名

    // 移动端调试工具
    // if(process.env.BASE_ENV=== 'development') {
    //   const vConsole = new VConsole()
    // }
    // const vConsole = new VConsole()

    // fastclick
    initReactFastclick()
  }, [])
  return <>{element}</>
}

export default App
