import { createSlice } from '@reduxjs/toolkit'
import * as utilsStorage from '@/utils/storage'
import * as types from '@/types'

export const homeDataSlice = createSlice({
  name: 'homeData',
  initialState: {
    pageConfig: null,
    baseInfo: null,
    mqttClient: null,
    isDefaultData: true,
    productInitConfig: {
      defaultSourceType: utilsStorage.getItem('systemProcess') === 'cloud' ? 'init' : '', //'', init为没有修正的数据， corr为修正数据
      bindDevice: false, // 板子是否需要绑定设备
      terminalName: 'EMS-PCS'
    }, 
    bindDeviceStatus: 'no', // 绑定设备完成
  },
  reducers: {
    saveBaseInfo: (state: types.PropNameAny, action) => {
      state.baseInfo = action.payload
    },
    savePageConfig: (state: types.PropNameAny, action) => {
      state.pageConfig = action.payload
    },
    saveMqttClient: (state: types.PropNameAny, action) => {
      state.mqttClient = action.payload
    },
    saveDefaultDataFlag: (state: types.PropNameAny, action) => {
      state.isDefaultData = action.payload
    },
    saveDefaultSourceType: (state: types.PropNameAny, action) => {
      state.productInitConfig.defaultSourceType = action.payload
    },
    saveProductInitConfig: (state: types.PropNameAny, action) => {
      state.productInitConfig = {...action.payload}
    },
    setBindDeviceStatus: (state: types.PropNameAny, action) => {
      state.bindDeviceStatus = action.payload
    }
  }
})
// Action creators are generated for each case reducer function
export const { 
  saveMqttClient, 
  saveDefaultDataFlag, 
  saveBaseInfo, 
  savePageConfig,
  saveDefaultSourceType,
  saveProductInitConfig,
  setBindDeviceStatus
} = homeDataSlice.actions

export default homeDataSlice.reducer
