import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import homeDataReducer from './reducer/home'
import dashboardDataSlice from './reducer/dashboard'

export default configureStore({
  reducer: {
    homeData: homeDataReducer,
    dashboardData: dashboardDataSlice
  },
  // 配置中间件
  // RTk已经默认使用了redux-thunk,这里不需要额外引入了
  // 如果需要一些自定义的中间件,可以通过调用getDefaultMiddleware
  // 并将结果包含在返回的中间件数组中
  // 案例中使用了日志的中间件,可以追踪到哪个页面在哪个时候使用了该reducer
  // 并且可以显示调用前的数据状态和调用后的数据状态
  middleware: (getDefaultMiddleware) =>
    // if(process.env.BASE_ENV=== 'development')
    getDefaultMiddleware({
      // https://stackoverflow.com/questions/61704805/getting-an-error-a-non-serializable-value-was-detected-in-the-state-when-using
      serializableCheck: false
    }).concat(logger)
})
