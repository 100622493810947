import * as utilsStorage from './storage'
import { Toast } from 'antd-mobile'
import * as types from '@/types'
import jsBridge from '@/jssdk/jsBridge'

const cloudDevDomain = 'http://120.79.169.65:9000'  
const cloudProDomain = 'https://mobile.alwayscontrol.net'
const cloudViewDomain = process.env.BASE_ENV === 'production' ? cloudProDomain : cloudDevDomain
// http://192.168.0.77:8080
const defaultDomain = {
  cloud: cloudViewDomain,
  cloudView: cloudViewDomain,
  local: '',
  //local: '',
  appName: 'alwaysControl'
}

const saveDomainToStorage = () => {
  // 本地开发域名处理
  if (process.env.NODE_ENV === 'development') {
    const host = `http://${window.location.host}`
    utilsStorage.setItem('commonDomain', {
      cloud: host,
      cloudView: host,
      local: '',
      appName: 'alwaysControl'
    })
    return
  }
  // 地址带有isDev参数，指定跳转地址，ip地址就是当前访问的地址
  const urlParams = new URL(window.location.href).searchParams
  const isDev: any = urlParams.get("isDev")
  const isDevType: any = urlParams.get('isDevType')
  if(isDev && isDevType) {
    utilsStorage.setItem('commonDomain', {
      cloud: isDevType === 'cloud-test' ? cloudDevDomain : cloudProDomain,
      cloudView: isDevType === 'cloud-test' ? cloudDevDomain : cloudProDomain,
      local: location.origin,
      appName: 'alwaysControl'
    })
    return;
  }

  // alert('isDevType'+ isDevType  + '保存域名测试')

  // APP环境
  if (jsBridge.isApp()) {
    jsBridge.callHandler('appInfo', null, (res: any) => {
      console.log(
        'appInfo:::',res,
        ':::',
        typeof res,
        'cloudViewDomain::',
        cloudViewDomain,
        'isIos:' + !jsBridge.isAndroid
      )
      const data = typeof res === 'string' ? JSON.parse(res) : res
      const ip = data?.ip.indexOf('http') > -1 ? data?.ip : 'http://' + data?.ip
      utilsStorage.setItem('commonDomain', {
        cloud: cloudViewDomain,
        cloudView: cloudViewDomain,
        local: ip,
        //local: data?.ip ? 'http://' + data?.ip : '', //http://192.168.0.39:8080/
        appName: data?.appName
      })
    })
    return
  } else {
    utilsStorage.setItem('commonDomain', defaultDomain)
  }
}

const getDomain: any = () => {
  const domain = utilsStorage.getItem('commonDomain')
  if (!domain) {
    saveDomainToStorage()
    return utilsStorage.getItem('commonDomain')
  }
  return domain
}

const getApiUrl = (url: string) => {
  const isPro = process.env.NODE_ENV === 'production'
  console.log(
    '>>>>>',
    'process.env.NODE_ENV::',
    process.env.NODE_ENV,
    'process.env.BASE_ENV:::',
    process.env.BASE_ENV
  )
  const commonDomain = getDomain()
  if (!commonDomain.local && isPro && utilsStorage.getItem('systemProcess') === 'local') {
    console.warn('care: local流程没有ip')
  }

  const isCloudPro = process.env.BASE_ENV === 'production'
  const apiUrl = url
  if (process.env.NODE_ENV === 'production') {
    if (apiUrl.indexOf('/cgi-bin/') > -1) {
      // 本地流程接口统一路径有cgi-bin
      return `${commonDomain.local}${apiUrl}`
    } else {
      if (isCloudPro) {
        // 正式上线
        return `${commonDomain.cloud}/pro-api${apiUrl}`
      }
      // 线上测试
      return `${commonDomain.cloud}/dev-api${apiUrl}`
      // return `${commonDomain.cloud}/pro-api${apiUrl}`
    }
  } else {
    if (url.indexOf('/cgi-bin/') > -1) {
      return apiUrl
    }
    return isCloudPro ? `/pro-api${apiUrl}` : `/dev-api${apiUrl}`
  }
}

export { cloudViewDomain, defaultDomain, getApiUrl, saveDomainToStorage, getDomain }
