import React, { useEffect, useState } from 'react'
import * as utilsStorage from '@/utils/storage'
import { defaultDomain } from '@/utils/host'
import { useLocation } from 'react-router-dom'

const useGuideToLogin = () => {
    const location = useLocation();
    useEffect(() => {
        // 没有登录信息，直接跳转到云端登录
        const userInfo = utilsStorage.getItem('userInfo') || null

        // 不需要跳转登录的页面
        const arr = ['/account/login', '/account/transfer', '/account/social-callback', '/account/register', '/account/forgetPassword', '/testGuide']
        if(arr.includes(location.pathname)) {
            return
        } else {
            // 指定测试地址
            const urlParams = new URL(window.location.href).searchParams
            const isDev: any = urlParams.get("isDev")
            const isDevType: any = urlParams.get('isDevType')
            if(!userInfo && isDev && isDevType) {
                const cloudDevDomain = 'http://120.79.169.65:9000'  
                const cloudProDomain = 'https://mobile.alwayscontrol.net'
                const saveTolocalDomain = encodeURIComponent(window.location.origin)
                const view = isDevType === 'cloud-test' ? cloudDevDomain : cloudProDomain
                window.location.href = `${view}/account/login?saveTolocalDomain=${saveTolocalDomain}&isDevType=${isDevType}`
               return
            }

            // 本地开发环境
            if(!userInfo && process.env.NODE_ENV === 'development') {
                const { pathname, origin } = window.location
                const redirectPath = encodeURIComponent(pathname)
                window.location.href = `/account/login?redirectPath=${redirectPath}`
                return;
            }
            if(!userInfo) {
                const { pathname, origin } = window.location
                const redirectPath = encodeURIComponent(pathname)
                // const redirectDomain = encodeURIComponent(origin)
                window.location.href = `${defaultDomain.cloud}/account/login?redirectPath=${redirectPath}`
            }
        }
    }, [])
}

export default useGuideToLogin