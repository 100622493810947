import React from 'react'
import ReactDOM, { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { Provider } from 'react-redux'
import store from '@/store'
import './index.scss'

const rootEle = document.getElementById('root')

if (rootEle) {
  const root = ReactDOM.createRoot(rootEle)
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        {' '}
        <App />
      </BrowserRouter>
    </Provider>
  )
}
