import React, { FC } from 'react'
import style from './index.scss'

const CommonLoading: FC<{
  text: string
  isShow?: boolean
}> = ({ text = '', isShow = true }) => {
  if (!isShow) return
  return (
    <div className={style['loadingBox']}>
      <div>
        <div className={style['loading']}></div>
        {text && <div className={style['explain']}>{text}</div>}
      </div>
    </div>
  )
}

const PageLoading: FC<{
  text?: any
  isShow: boolean
  children?: React.ReactNode
}> = ({ text = '', isShow = true, children }) => {
  const onClick = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
  }
  if (!isShow) return
  return (
    <div className={style['loadingPageBox']}>
      <div className={style['marsk']} onClick={(e) => onClick(e)}></div>
      <div className={style.flex}>
        <div className={style['container']}>
          <div className={style['loading']}></div>
          {text && <div className={style['explain']}>{text}</div>}
          {children && children}
        </div>
      </div>
    </div>
  )
}

export { CommonLoading, PageLoading }
