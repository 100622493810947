import { saveDomainToStorage } from './host'
/**
 * localStorage: localStorage资源不能滥用，而且要被统一管理
 * 注意：整个项目的localStorage都必须在此模块定义
 * 支持JSON
 */

const localStorage = window.localStorage

const WHITE_LIST = [
  'userToken', // 用户登录token
  'userInfo', // 用户信息
  'systemProcess', // cloud/local 两个流程
  //'checkTerminalDevice', // 需要隐藏终端设备绑定步骤 1为校验终端通过，2为校验设备通过
  'userAuto', // 用户自动登录
  //'umi_locale', // ant-design自带的切换语言用的
  'sysTerminalId', // 终端编号
  'terminalInfo', // 终端信息
  'workingMode', // 工作模式
  'commonDomain', // 保存域名
  'testHideBindDevice' // 仅测试使用
]

function warn(key: string) {
  if (WHITE_LIST.indexOf(key) === -1) {
    console.warn(`警告：白名单没有(${key})`)
  }
}

export const keys = () => {
  return Object.keys(localStorage)
}

// 获取到的value会自动进行JSON.parse操作
export function getItem(key: string) {
  warn(key)
  let value: any = window.localStorage.getItem(key)
  try {
    value = JSON.parse(value)
  } catch (error) {
    // to
  }
  return value
}

// 保存的value如果是object会自动序列化
export function setItem(key: string, value: any) {
  warn(key)
  if (typeof value === 'object') {
    value = JSON.stringify(value)
  }

  return window.localStorage.setItem(key, value)
}

export function removeItem(key: string) {
  return localStorage.removeItem(key)
}

export function removeAll() {
  WHITE_LIST.forEach((item) => {
    removeItem(item)
  })
  saveDomainToStorage() // 域名一定要有
}
