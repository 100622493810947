import React, { lazy } from 'react'
import { CommonLoading } from '@/components/Loading'
import ErrorBoundary from '@/components/ErrorBoundary'

interface Route {
  path?: string
  name: string
  element?: React.ReactNode
  children?: Route[]
  meta?: Record<string, any>
}

// 路由懒加载
const lazyLoad = (path: string) => {
  const Comp = lazy(() => import(`../src/pages/${path}`))
  return (
    <ErrorBoundary>
      <React.Suspense
        fallback={
          <div style={{ height: '100vh' }}>
            <CommonLoading text="页面正在加载中，请稍候..." />
          </div>
        }
      >
        <Comp />
      </React.Suspense>
    </ErrorBoundary>
  )
}

const router: Route[] = [
  {
    path: '/home',
    name: '首页',
    element: lazyLoad('Home')
  },
  {
    path: '/',
    name: '首页',
    element: lazyLoad('Home')
  },
  {
    path: '/dashboard',
    name: '统计图表',
    element: lazyLoad('Dashboard')
  },
  {
    path: '/terminalAuth',
    name: '终端认证',
    element: lazyLoad('TerminalAuth')
  },
  {
    path: '/bindDevice',
    name: '绑定设备',
    element: lazyLoad('BindDevice')
  },
  {
    path: '/notice',
    name: '消息中心',
    element: lazyLoad('Notice')
  },
  {
    path: '/question',
    name: '帮助与支持',
    element: lazyLoad('Qestion')
  },
  {
    path: '/deleteAccount',
    name: '注销账号',
    element: lazyLoad('DeleteAccount')
  },
  {
    path: '/extraInfo',
    name: '用户须知',
    // element: <Notice />,
    children: [
      {
        path: '/extraInfo/userNotice',
        name: '用户须知',
        element: lazyLoad('Extra/UserNotice')
      }
    ]
  },

  {
    path: '/settings',
    name: '设置',
    element: lazyLoad('Settings'),
    children: [
      {
        path: '/settings/all',
        name: '设置',
        element: lazyLoad('Settings')
      },
      {
        path: '/settings/terminalName',
        name: '修改终端名字',
        element: lazyLoad('Settings/components/TerminalName')
      },
      {
        path: '/settings/gridPrice',
        name: '修改终端名字',
        element: lazyLoad('Settings/components/GridPrice')
      }
    ]
  },
  {
    path: '/account',
    name: '会员中心',
    // element: <MemberCenter />,
    children: [
      {
        path: '/account/login',
        name: '登录',
        element: lazyLoad('Account/Login')
      },
      {
        path: '/account/social-callback',
        name: '第三方登录',
        element: lazyLoad('Account/Login/components/LoginTransfer')
      },
      {
        path: '/account/register',
        name: '注册',
        element: lazyLoad('Account/Register')
      },
      {
        path: '/account/forgetPassword',
        name: '忘记密码',
        element: lazyLoad('Account/ForgetPassword')
      },
      {
        path: '/account/memberCenter',
        name: '我的',
        element: lazyLoad('Account/MemberCenter')
      },
      {
        path: '/account/memberCenter/message',
        name: '消息中心',
        element: lazyLoad('Account/MemberCenter/components/MoreAbout/components/Message')
      },
      {
        path: '/account/memberCenter/notice',
        name: '通知设置',
        element: lazyLoad('Account/MemberCenter/components/MoreAbout/components/Notice')
      },
     
      {
        path: '/account/memberCenter/help',
        name: '帮助与支持',
        element: lazyLoad('Account/MemberCenter/components/MoreAbout/components/Help')
      },
      {
        path: '/account/memberCenter/feedback',
        name: '意见与反馈',
        element: lazyLoad('Account/MemberCenter/components/MoreAbout/components/FeedBack')
      },
      {
        path: '/account/memberCenter/about',
        name: '关于旭衡',
        element: lazyLoad('Account/MemberCenter/components/MoreAbout/components/About')
      },
     
      {
        path: '/account/userInfo',
        name: '用户信息',
        element: lazyLoad('Account/UserInfo')
      },
      {
        path: '/account/transfer',
        name: '本地流程登录中转',
        element: lazyLoad('Account/LocalLoginTransfer')
      }
    ]
  },
  // {
  //   path: '/config', // 流程配置页面
  //   name: '流程配置',
  //   children: [
  //     {
  //       path: '/config/syProcessSwitch',
  //       name: '环境切换', // 本地流程还是云端流程
  //       element: lazyLoad('Config/SyProcessSwitch')
  //     },
  //     {
  //       path: '/config/wifi',
  //       name: 'Wifi配置',
  //       element: lazyLoad('Config/Wifi')
  //     }
  //   ]
  // },
  {
    path: '*',
    name: '404页面',
    element: lazyLoad('NotFound')
  },
  {
    path: '/testGuide',
    name: '调试测试引导页面',
    element: lazyLoad('TestGuide')
  }
]

export default router
