const isAndroid = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1
const isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

// 搬运 https://juejin.cn/post/6979424998179995661?searchId=20240103143913C0F10330D999AB2CE120
//这是必须要写的，用来创建一些设置
function setupWebViewJavascriptBridge(callback) {
  //Android使用
  if (isAndroid) {
    if (window.WebViewJavascriptBridge) {
      callback(window.WebViewJavascriptBridge)
    } else {
      document.addEventListener(
        'WebViewJavascriptBridgeReady',
        () => {
          callback(window.WebViewJavascriptBridge)
        },
        false
      )
    }
  }

  //iOS使用
  if (isiOS) {
    if (window.WebViewJavascriptBridge) {
      return callback(window.WebViewJavascriptBridge)
    }
    if (window.WVJBCallbacks) {
      return window.WVJBCallbacks.push(callback)
    }
    window.WVJBCallbacks = [callback]
    const WVJBIframe = document.createElement('iframe')
    WVJBIframe.style.display = 'none'
    WVJBIframe.src = 'wvjbscheme://__BRIDGE_LOADED__'
    document.documentElement.appendChild(WVJBIframe)
    setTimeout(() => {
      document.documentElement.removeChild(WVJBIframe)
    }, 0)
  }
}

// 判断是不是App环境
function isApp() {
  if (navigator.userAgent.indexOf('appName/alwaysControl') > -1) {
    return true
  }
  return false
}
if (isApp()) {
  //注册回调函数，第一次连接时调用 初始化函数(android需要初始化,ios不用)
  setupWebViewJavascriptBridge((bridge) => {
    if (isAndroid) {
      //初始化
      bridge.init((message, responseCallback) => {
        const data = {
          'Javascript Responds': 'Wee!'
        }
        responseCallback(data)
      })
    }
  })
}

export default {
  // js调APP方法 （参数分别为:app提供的方法名  传给app的数据  回调）
  callHandler(name, data, callback) {
    setupWebViewJavascriptBridge((bridge) => {
      bridge.callHandler(name, data, callback)
    })
  },
  // APP调js方法 （参数分别为:js提供的方法名  回调）
  registerHandler(name, callback) {
    setupWebViewJavascriptBridge((bridge) => {
      bridge.registerHandler(name, (data, responseCallback) => {
        callback(data, responseCallback)
      })
    })
  },
  isApp,
  isAndroid,
  isiOS
}
