import { createSlice } from '@reduxjs/toolkit'
import * as utilsStorage from '@/utils/storage'
import * as types from '@/types'

export const dashboardDataSlice = createSlice({
  name: 'dashboardData',
  initialState: {
    canvasData: {
      timeRange: null, // 时间范围
      openEyesData: null, // 打开眼睛需要展示的数据
      defaultData: null, // 默认数据
      socData: null, // soc数据
      apiData: null, // 接口返回的所有数据
      yaxis: 10, // y轴坐标间距控制
      currentTabName: '', // 当前选择tab
      canvasUseTime: '', // 当前选中时间
      isOpenEyes: false
    },
  },
  reducers: {
    saveCanvasData: (state: types.PropNameAny, action) => {
      state.canvasData = action.payload
    },
  }
})
// Action creators are generated for each case reducer function
export const { 
    saveCanvasData,
} = dashboardDataSlice.actions

export default dashboardDataSlice.reducer
